import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  Grid,
  Product,
  ProductLine,
  Title,
  ProductImg,
  BuyButton,
} from "./styles"

const ProductGrid = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allPrismicShopItem {
          edges {
            node {
              data {
                buy_button_code
                url_slug
                order
                images {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, srcSetBreakpoints: [ 650, 960, 1280, 1920 ]) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
                title
              }
            }
          }
        }
      }
    `
  )

  const itemsSorted = data.allPrismicShopItem.edges.sort((a, b) => {
    return b.node.data.order - a.node.data.order
  })

  return (
    <Grid>
      {itemsSorted ? (
        itemsSorted.map((item, index) => {
          const { title, url_slug, images } = item.node.data

          return (
            <Product key={index}>
              <Link to={`/product/${url_slug}`}>
                <ProductImg
                  fluid={images[0].image.localFile.childImageSharp.fluid}
                  alt={title}
                />
              </Link>
              <div>
                <ProductLine to={`/product/${url_slug}`}>
                  <Title>{title}</Title>
                </ProductLine>
                <BuyButton to={`/product/${url_slug}`}>BUY NOW</BuyButton>
              </div>
            </Product>
          )
        })
      ) : (
        <p>No Products found!</p>
      )}
    </Grid>
  )
}

export default ProductGrid
