import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints, Container, Img } from "../../utils/styles"

export const Grid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  gap: 25px;

  @media (min-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${breakpoints.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.xxl}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Product = styled.div`
  display: flex;
  min-height: 400px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const ProductLine = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
`

export const ProductImg = styled(Img)`
  margin: 0;
  min-height: 400px;

  & img {
    object-fit: contain !important;
    margin: 0;
  }
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  font-family: "HelveticaNeue-Regular";
  color: #000000;
  text-transform: uppercase;
  margin: 0.5rem 0;
`

export const PriceTag = styled.span`
  font-weight: 300;
  font-size: 14px;
  font-family: monospace;
  color: #000000;
  margin: 0.5rem 0 0.5rem 6px;
  text-align: center;
`

export const BuyButton = styled(Link)`
  font-weight: 300;
  text-align: center;
  font-family: "HelveticaNeue-Regular";
  color: #000000;
  border: 1px solid #000000;
  border-radius: 20px;
  text-decoration: none;
  line-height: 20px;
  width: 100%;
  max-width: 130px;
  padding: 3px 12px;
  margin: auto;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #000000;
    color: white;
  }
`
