import React from "react"
import SEO from "../components/seo"
import ProductGrid from "../components/ProductGrid"

const ShopPage = () => (
  <>
    <SEO title="Shop" />
    <ProductGrid />
  </>
)

export default ShopPage
